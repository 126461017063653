import styled from "styled-components";


export const AlegeriStyled = styled.div`
	h2{
		text-align: center;
	}
	h4{
		margin-top: 1.5em;
	}
`