const Contact = () => {

return (
	<div className="content_inner">
		<p>Sediul Colegiului: 530152 Miercurea Ciuc, Str. Harghita, nr. 6, jud.HR</p>
		<p>Email: <a href="mailto:office@colegiulmedicilorhr.ro">office@colegiulmedicilorhr.ro</a></p>
		
		<p>Tel: <a href="tel:(004)0266-371688">(004)0266-371688</a></p>
		<h2>Program de lucru:</h2>
		<ul>
			<li>Luni: 8:00-14:00</li>
			<li>Marți: 10:00-18:00</li>
			<li>Miercuri: 8:00-14:00</li>
			<li>Joi: 8:00-14:00</li>
			<li>Vineri: fără program cu publicul</li>
		</ul>
		<h2>Cont bancar:</h2> 
		RO58RNCB0159024969060001, BCR Filiala Miercurea Ciuc
		<p>CIF 9380922</p>
	
	</div>
)

}

export default Contact;